code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

button {
  display: block;
  margin-left: 0;
  margin-top: 30px;
}

button[primary] {
  color: black;
}

